export const PATHS = {
  AUTH: {
    BASE: 'auth',
    LOGIN: {
      BASE: 'login'
    },
  },
  REGISTER: {
    BASE: 'register'
  },
  EMPTY: '',
  WILDCARD: '**'
}
