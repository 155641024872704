import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-login-redirection',
  templateUrl: './login-redirection.component.html',
  styleUrls: ['./login-redirection.component.scss']
})
export class LoginRedirectionComponent implements OnInit{

  constructor(
    public router: Router
  ){
  }

  ngOnInit() {
    this.router.navigate(['/auth/login'], { queryParamsHandling: 'merge' })
  }
}
