import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CookieService } from 'ng2-cookies';
import { CognitoAuthResponse, CognitoUserAttributes } from 'src/app/shared/models/cognito.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  private cookieDomain = '.amcham.com.ar';
  private accessTokenCookie = 'cogToken';
  private refreshTokenCookie = 'cogRefreshToken';
  private idTokenCookie = 'cogIdToken';

  constructor(
    private http: ApiService,
    private cookieService: CookieService,
  ) { }

  /**
   * Returns a paginated list of Users registered in Cognito,  
   * optionally searching by email and filtering attributes
   * @param email User's email to look for 
   * @param attributes Attributes to return. Default: all attributes returned
   */
  public listCognitoUsers(email?: string, attributes?: string[]) {
    let params = new HttpParams();
    if (email) params = params.append('findmail', email);
    if (attributes) {
      attributes.forEach(attr => {
        params.append('attributes', attr)
      })
    }
    return this.http.get('cognito', params);
  }

  /**
   * Attempts to SignIn a User in Cognito.  
   * If it fails, attempts to Register with the attributes provided.
   * @param email Email of the user (acts as username)
   * @param password Password of the user
   * @param updateCurrent Attribute to retry: _null | 'mobilephone' | 'password'_
   * @param attributes Object containing user attributes
   */
  public verifyCognitoAndRegister(
    email: string, password: string,
    updateCurrent: string | null,
    attributes: {[key: string]: string }
  ) {
    const body = {
      email, password,
      updateCurrent,
      ...attributes
    }

    return this.http.post('cognito/check', body);
  }

  /**
   * Register a new User in Cognito with the attributes provided.
   * @param email Email of the User (acts as username)
   * @param password Password of the User
   * @param attributes Object containing User attributes
   */
  public registerCognitoUser(
    email: string, password: string,
    attributes: CognitoUserAttributes | {}
  ) {
    const body = {
      email, password,
      ...attributes
    }

    return this.http.post('cognito/signup', body);
  }

  /**
   * Completes registration of a User in Cognito using a confirmation code.
   * @param email Email of the User (acts as username)
   * @param confirmationCode Six-digit confirmation code (passed as string)
   */
  public confirmCognitoUser(email: string, confirmationCode: string) {
    const body = {
      email,
      confirmationCode
    };
    return this.http.post('cognito/confirm', body);
  }

  /**
   * Requests a new confirmation code from Cognito for the provided email.
   * @param email Email of the User (acts as username)
   */
  public requestNewConfirmationCode(email: string) {
    const params = new HttpParams().set('u', email);
    return this.http.get('cognito/confirm', params);
  }

  /**
   * Starts a Forgot Password sequence. Sends a confirmation code to User's email.
   * @param email Email of the User (acts as username)
   */
  public startForgotPassword(email: string ) {
    const params = new HttpParams().set('u', email);
    return this.http.get('cognito/password', params);
  }

  /**
   * Completes the Forgot Password sequence,  
   * resetting User's password to the one provided.
   * @param email Email of the User (acts as username)
   * @param pass New Password associated to the User
   * @param confirmationCode Six-digit confirmation code (passed as string)
   */
  public completeForgotPassword(
    email: string, pass: string,
    confirmationCode: string
  ) {
    const body = {
      email,
      pass,
      confirmationCode
    }
    return this.http.put('cognito/password', body);
  }

  /**
   * Authenticates a User in Cognito and returns the corresponding tokens.
   * @param email Email of the User (acts as username)
   * @param password Password of the User
   */
  public signInCognitoUser(email: string, password: string) {
    const body = {
      email,
      password
    }
    return this.http.post<CognitoAuthResponse>('cognito/signin', body);
  }

  /**
   * Reads Cognito cookies and returns Refresh Token.
   */
   public getRefreshToken() {
    return this.cookieService.get(this.refreshTokenCookie);
  }

  /**
   * Refresh Access and Id tokens using a Refresh token.
   */
  public refreshAccessToken() {
    const refreshToken = this.getRefreshToken();
    const body = {
      refreshToken
    }

    return this.http.post('cognito/token', body);
  }

  /**
   * List the attributes stored in Cognito for an authenticated User.  
   * A valid Access token must be provided as Bearer.
   */
  public getUserAttributes() {
    return this.http.get('cognito/attributes');
  }

  /**
   * Update in Cognito the provided attributes for an authenticated User.  
   * A valid Access token must be provided as Bearer.
   */
  public updateUserAttributes(attributes: any) {
    const body = {
      ...attributes
    }

    return this.http.put('cognito/attributes', body);
  }

  /**
   * Change the password for an authenticated User.  
   * A valid Access token must be provided as Bearer.
   * @param oldPassword The current password
   * @param proposedPassword The desired new password
   */
  public changeOwnPassword(oldPassword: string, proposedPassword: string) {
    const body = {
      oldPassword,
      proposedPassword
    }

    return this.http.post('cognito/password', body);
  }

  /**
   * Attempts to revoke all tokens issued to the current User
   */
  public signOutCognitoUser() {
    return this.http.get('cognito/signout');
  }

  /**
   * Reads Cognito cookies and sets Authorization header if found.
   * @param tokenType Specify the token to load: 'access' | 'id'
   */
  public loadCognitoUser(tokenType?: string) {
    let token: string;
    if (tokenType == 'id') {
      token = this.cookieService.get(this.idTokenCookie);
    } else {
      token = this.cookieService.get(this.accessTokenCookie);
    }

    if (token) {
      // Set Authorization Header for all requests.
      this.http.setHeader('Authorization', 'Bearer ' + token);
    }
  }

  /**
   * Sets cookies with the tokens returned by Cognito.
   * @param authUser Cognito user authentication response.
   */
   public setCognitoUser(authUser: CognitoAuthResponse) {
    const accessToken = authUser.AccessToken;
    const refreshToken = authUser.RefreshToken;
    const idToken = authUser.IdToken;
    const expiresIn = new Date();
    expiresIn.setSeconds(expiresIn.getSeconds() + authUser.ExpiresIn);

    this.cookieService.set(this.accessTokenCookie, accessToken, expiresIn, '/', this.cookieDomain);
    this.cookieService.set(this.refreshTokenCookie, refreshToken as string, 30, '/', this.cookieDomain);
    this.cookieService.set(this.idTokenCookie, idToken, expiresIn, '/', this.cookieDomain);

    // Set Authorization Header for all requests.
    this.http.setHeader('Authorization', 'Bearer ' + accessToken);
  }

  /**
   * Clears all Cognito related cookies.
   */
   public clearCognitoCookies(): void {
    this.cookieService.delete(this.accessTokenCookie);
    this.cookieService.delete(this.refreshTokenCookie);
    this.cookieService.delete(this.idTokenCookie);
  }
}
