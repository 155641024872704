<div id="login-page">
    <div class="amcham-box small ">
      <div class="container-fluid">
        <div class="amcham-box-body login-box border border-ligth-gray" >
          <div class="row">
            <div class="col">
              <img id="logo" src="assets/images/amcham-logo-small.jpg" alt="logo-amcham">
              <h1 class="sesion  d-inline-block ">Iniciar sesión</h1>

              <br><br>
              <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
              </div>
              <form [formGroup]="loginForm">
                <div class="form-group">
                  <div class="input-group ">
                    <input type="text"  id="email" class="form-control " placeholder="Correo electrónico" formControlName="email" [class.is-invalid]="isInvalid('email')" [class.is-valid]="isValid('email')">
                    <div class="invalid-feedback" *ngIf= "loginForm.controls['email']?.errors?.['patter']" > El correo electrónico no es válido. </div>
                    <div class="invalid-feedback" *ngIf= "loginForm.controls['email']?.errors?.['required']" > El correo electrónico es obligatorio. </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <input type="password" id="contraseña" class="form-control" placeholder="Contraseña" formControlName="password" [class.is-invalid]="isInvalid('password')" [class.is-valid]="isValid('password')">

                    <div class="invalid-feedback" *ngIf= "loginForm.controls['password']?.hasError('minlength')" >La contraseña debe contener al menos 6 caracteres. </div>
                    <div class="invalid-feedback" *ngIf= "loginForm.controls['password'].hasError('required')" > La contraseña es obligatoria. </div>
                  </div>
                  <div class="recovery-pass">
                    <a href={{forgotPassword}} class="recovery-pass">
                      ¿Olvidaste tu contraseña?
                    </a>
                  </div><br>
                </div>
                <div class="centro" >
                  <div class="input-group-login" >
                    <button type="submit" class="btn btn-primary rounded-pill" (click)="login()" [disabled]="!buttonEnabled() || showSpinner">
                      <span *ngIf="showSpinner">
                        <div class="spinner-border text-light" role="status">
                          <span class="sr-only">Cargando...</span>
                        </div>
                      </span>
                      <span *ngIf="!showSpinner" class=" iniciar-sesion text-white " >
                        Iniciar sesión
                      </span>
                    </button>
                  </div><br>
                </div>
                <br><br>
                <hr>
                <h5 class="no-usuario d-inline-block ">¿No tenés un usuario?</h5><br>
                <div class="centro">
                  <a href={{urlNewUser}} class="btn btn-outline-primary btn-lg btn-block rounded-pill crea-cuenta">
                    Creá tu cuenta
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
