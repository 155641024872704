import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CognitoErrorResponse } from 'src/app/shared/models/cognito.model';
import { UserService } from 'src/app/core/services/api/user/user.service';
import { CognitoService } from 'src/app/core/services/api/user/cognito.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  public loginForm: FormGroup;
  public showSpinner: boolean;
  public errorMessage: string = "";
  public eventId: string = "";
  public redirect: boolean = false;
  public redirectUrl: string = "";
  public fromEvent: boolean = false;
  public forgotPassword: string = "";
  public urlNewUser: string = "";


  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public cognitoService: CognitoService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    // Email Regex (RFC 5322 Official Standard)
    // tslint:disable-next-line: max-line-length
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.pattern((emailRegex)),
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(320)
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(256)
      ]))
    });

    this.showSpinner = false;

    this.route.queryParams.pipe(take(1)).subscribe(
      (res: any) => {
        this.redirect = res.r;
        this.redirectUrl = res.redirect_to;
        this.eventId = res.event;
        this.fromEvent = false;
        this.userService.fromEvent = false;
        if (this.eventId && this.eventId.length > 0){
          this.fromEvent = true;
          this.userService.fromEvent = true;
          this.userService.eventId = this.eventId;
        }
      },
      (err: any) => console.error(err)
    );
  }

  ngOnInit() {
    this.constructURLs();
  }

  ngAfterViewInit() {
    this.resetForm();
  }

  /**
   * Construct URLs with dynamic redirection value
   */
  constructURLs() {
    const fromUrl = this.redirectUrl ? this.redirectUrl : environment.connectUrl;
    const baseUrl = window.location.origin;
    // this.forgotPassword = `${environment.baseUrl}password?redirect_to=${fromUrl}`;
    // this.urlNewUser = `${environment.baseUrl}guest/?redirect_to=${fromUrl}`;
    this.forgotPassword = `/password?redirect_to=${fromUrl}`;
    this.urlNewUser = `/register/?redirect_to=${fromUrl}`;
  }

  /**
   * resetForm
   */
  public resetForm(email?: string): void {
    this.loginForm.reset();
    if(email) {
      this.loginForm.controls['email'].setValue(email);
    }
    this.showSpinner = false;
  }


  /**
   * Tries to log-in an user and sets the state of the
   * page accordingly.
   */
  public login(): void {
    this.showSpinner = true;
    this.errorMessage = "";

    const email = this.loginForm.controls['email'].value;
    const password = this.loginForm.controls['password'].value;

    this.userService.logIn(email, password).subscribe(
      (res: any) => {
        if (res.cognito && (res.cognito.UserSub || res.cognito.statusCode === 400)) {
          /*
            Depending on the Cognito response object properties, the 'action' is decided:
            res.cognito.UserSub: User successfully registered in Cognito => proceed to confirm
            res.cognito.statusCode: User registration in Cognito failed => parse error and proceed
          */
          const action = res.cognito.UserSub ? 'login_confirm' : this.actionOnCognitoError(res.cognito);
          const redirect_to = this.redirectUrl ? this.redirectUrl : null;
          const params = {
            action,
            redirect_to,
          }

          this.userService.setMailUserNewForm(email);
          this.userService.setPassUserNewForm(password);
          this.userService.getCognitoUserSubject().next({ cognito: res.cognito, contact: res.contact });
          this.router.navigate(['/forget', res.contact.contactid, 'verify'], { queryParams: params });
        } else {
          this.cognitoService.setCognitoUser(res.cognito);
          if (this.redirectUrl && this.redirectUrl.indexOf('connect.amcham.com.ar') !== -1) {
            const newRedirect = `redirect_to=${this.redirectUrl}`;
            const index = res.appURL.indexOf('redirect_to');
            const replaceUrl = res.appURL.substring(0, index) + newRedirect;
            window.location.replace(replaceUrl);
          } else if(!this.redirectUrl) {
            window.location.replace(res.appURL);
          }
          else {
            window.location.replace(this.redirectUrl);
          }
        }
      },
      (err: any) => {
        this.errorMessage = err.error.errors[0].msg
        ;
        this.resetForm(email);
      }
    );
  }

  /**
   * Parses a Cognito error object and returns a string to use as a query param.
   * @param cognitoError A Cognito error response object.
   */
  actionOnCognitoError(cognitoError: CognitoErrorResponse) {
    let action: string = "";
    if (cognitoError.message.indexOf('phone') > -1) action = 'login_phone';
    if (cognitoError.code === 'InvalidPasswordException') action = 'login_pass';
    if (cognitoError.code === 'UserNotConfirmedException') action = 'login_confirm';
    if (cognitoError.code === 'UsernameExistsException') action = 'login_conflict';
    return action;
  }


  /**
   * Determines whether a field has a valid value or not.
   * @param fieldName Field name based on the FormControl property.
   * @returns true if valid.
   */
  public isValid(fieldName: string): boolean {
    const field = this.loginForm.controls[fieldName];

    return field.valid && field.touched;
  }


  /**
   * Determines whether a field has an invalid value or not.
   * @param fieldName Field name based on the FormControl property.
   * @returns true if invalid.
   */
  public isInvalid(fieldName: string): boolean {
    const field = this.loginForm.controls[fieldName];

    return field.invalid && field.touched;
  }


  /**
   * Determines whether the submit button is enabled or not based
   * on the login form status.
   * @returns true if the log in form is valid.
   */
  public buttonEnabled(): boolean {
    return this.loginForm.valid;
  }

  public toLogin(): void {
    this.userService.toSSOLogin();
  }

}
