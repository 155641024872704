import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PATHS } from './core/utils/router-path';
import { LoginRedirectionComponent } from './modules/redirection/login-redirection/login-redirection.component';

const routes: Routes = [
  {
    canActivate: [],
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
    path: PATHS.AUTH.BASE
  },
  {
    canActivate: [],
    loadChildren: () =>
      import('./modules/register/register.module').then(m => m.RegisterModule),
    path: PATHS.REGISTER.BASE
  },
  {
    canActivate: [],
    loadChildren: () =>
      import('./modules/forget/forget.module').then(m => m.ForgetModule),
    path: 'password',
    data: { state: 'password' }
  },
  {
    path: PATHS.WILDCARD,
    component: LoginRedirectionComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true } )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
