import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { CookieService } from 'ng2-cookies';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: any = null;
  public dataUser: any;
  public fromEvent: boolean = false;
  public eventId: string = "";
  public mailUserNewForm: string;
  private passUserNewForm: string = "";
  public partnerId: string = "";
  private registerUserSubject = new BehaviorSubject(null);
  private cognitoUserSubject = new BehaviorSubject(null);

  constructor(
    public http: ApiService,
    public cookieService: CookieService,
    public router: Router,
  ) {
    this.mailUserNewForm = '';
    this.loadUser();
  }

  public getEventId(): string{
    return this.eventId;
  }

  public getDataUser(): any {
    return this.dataUser;
  }

  public getPartnetId(): string {
    return this.partnerId;
  }

  public toSSOLogin() {
    window.location.href = 'https://login.amcham.com.ar/?client_id=connect';
  }

  public setMailUserNewForm(val: string): void {
    this.mailUserNewForm = val;
  }

  public getMailUserNewForm(): string {
    return this.mailUserNewForm;
  }
  
  public setPassUserNewForm(val: string): void {
    this.passUserNewForm = val;
  }

  public getPassUserNewForm(): string {
    return this.passUserNewForm;
  }

  public getContactId(): string {
    return this.cookieService.get('contactId');
  }

  public getRegisterUserSubject(): BehaviorSubject<any>{
    return this.registerUserSubject;
  }
  
  public getCognitoUserSubject(): BehaviorSubject<any>{
    return this.cognitoUserSubject;
  }
  
  /**
   * logIn
   * @param email User's E-Mail
   * @param password User's Password
   */
  public logIn(email: string, password: string): Observable<object> {
    const body = { email, password };

    return this.http.post<Object>('contacts/login', body);
  }


  /**
   * setUser
   * @param user Server user response.
   */
  public setUser(user: any): void {
    this.user = user.contact.fullname;

    // TODO: [AECP-88] Contact Object it's too long to sotre in a cookie.
    const contact = user.contact.fullname;
    const token = user.token;
    const refreshToken = user.refreshToken;
    const contactId = user.contact.contactid;

    this.cookieService.set('contactId', contactId, undefined, '/');
    this.cookieService.set('contact', contact, undefined, '/');
    this.cookieService.set('token', token, undefined, '/');
    this.cookieService.set('refreshToken', refreshToken, undefined, '/');

    // Set Authorization Header for all requests.
    this.http.setHeader('Authorization', 'Bearer ' + user.token);
  }


  /**
   * loadUser
   */
  public loadUser(): void {
    // TODO: Secure Cookies against CSRF.
    const contactId = this.cookieService.get('contactId');
    const contact = this.cookieService.get('contact');
    const token = this.cookieService.get('token');
    const refreshToken = this.cookieService.get('refreshToken');

    if (contactId && contact && token && refreshToken) {
      this.user = contact;

      // Set Authorization Header for all requests.
      this.http.setHeader('Authorization', 'Bearer ' + token);
    }
  }


  /**
   * isLoggedIn
   */
  public isLoggedIn(): boolean {
    if (this.user) {
      return true;
    }

    return false;
  }


  public getRefreshToken(): string {
    return this.cookieService.get('refreshToken');
  }


  /**
   * refreshToken
   */
  public refreshToken(refreshToken: string) {
    const body = {
      refreshToken
    };

    return this.http.post('users/token', body);
  }


  /**
   * logOut
   */
  public logOut(): void {
    this.user = null;
    this.cookieService.delete('contactId');
    this.cookieService.delete('contact');
    this.cookieService.delete('token');
    this.cookieService.delete('refreshToken');
    this.cookieService.deleteAll('/');
  }

  /**
   * Starts a legacy Forgot Password sequence. Sends a reset link to User's email.
   * @param email Email of the User (acts as username)
   */
   public startResetPassword(email: string, redirectUrl?: string ) {
    let params = new HttpParams().set('email', email);
    if (redirectUrl) params = params.append('redirect_to', redirectUrl);
    return this.http.get('contacts/resetpass', params);
  }

  /**
   * Checks a URL Id that was sent as a reset link to User's email.
   * @param urlId Id extracted from the URL
   */
  public getIsResetLinkValid(urlId: string) {
    const body = {
      urlId
    }
    return this.http.put('contacts/resetpass', body);
  }
  
  /**
   * Completes the legacy Forgot Password sequence,  
   * resetting User's password to the one provided.
   * @param urlId Id extracted from the URL
   * @param email Email of the User (acts as username)
   * @param pass New Password associated to the User
   */
  public completeResetPassword(urlId: string, email: string, pass: string) {
    const body = {
      urlId,
      email,
      pass,
    }
    return this.http.post('contacts/resetpass', body);
  }
  
  /**
   * send Email recovery password */

  public sendEmailPassword(email: string, pass: string) {
    let body;
    if (pass.length > 0) {
      body = { email, pass };
    } else {
      body = { email };
    }
    return this.http.post('contacts/password', body);
  }
   
  /**
  * get user or Contact myAccout */
  public getContactCRM(contactId: string, action: string) {
    const params = new HttpParams()
      .set('action', action)
      .set('contactId', contactId);
    return this.http.get(`contacts/myaccount/${contactId}`, params);
  }

  public getMyEvents(month: string, year: string, contactId: string) {
    const params = new HttpParams()
      .set('contactId', contactId)
      .set('month', month)
      .set('year', year);
    return this.http.get(`contacts/myaccount/events/${contactId}`, params);
  }

   /**
  * get department'list */
  public getDepartments() {
    const params = new HttpParams()
    return this.http.get('department', params);
  }

  public registerInteres(contactId: string, typeInteres: string, field: string, value: boolean) {
    const body = { contactId, typeInteres, field, value };
    return this.http.post('contacts/myaccount/interest', body);
  }

  /**
  * get user or Contact form Web */

  public getContactDetails(id: string) {
    const body = { id };
    return this.http.post('contacts/web', body);
  }

  public getVerifyContact(email: string, accountId: string) {
    const body = { email, accountId };
    return this.http.post('contacts/verify', body);
  }

  public registerUserGuest(
    companyName: string, companyId: string,
    sector: string, firstName: string,
    lastName: string, email: string, pass: string,
    mobilePhone: string, linkedin: string,
    departament: string, jobTitle: string, 
    jerarquia: string, interests: [],
    eventId = this.eventId,
   
  ) {
    const body = {
      companyName, companyId,
      sector, firstName,
      lastName, email, pass,
      mobilePhone, linkedin, departament,
      jobTitle, jerarquia, interests, eventId,
     
    };
    return this.http.post(`contacts/guest/register/`, body);    
  }

  
}
