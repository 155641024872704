import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PATHS } from 'src/app/core/utils/router-path';

const routes: Routes = [
  {
    component: LoginComponent,
    path: PATHS.AUTH.LOGIN.BASE
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
